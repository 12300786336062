import React,{useEffect,useState} from 'react'
import {PrintContainer} from '../../styles/Print';
import { useOrderRequests } from '../../helpers/OrderRequests';
export default function PrintOrders() {

  const [orders,setOrders]=useState(null)
  const [total,setTotal]=useState(0)
  const {getOrders}=useOrderRequests()
  useEffect(() => {
    if(orders==null){
      getOrders().then(async (response)=>{
        setOrders(response.data.data)
        let totals=0
        let unit=""
        const seter=await response.data.data.map(x=>{
          unit=x.currencyUnit
          totals+=(x.totalCurrencyPrice)
        })
        setTotal(unit+totals)

        setTimeout(() => {
          window.print()
        }, 2000);
      })
    }
  }, [orders])
  

  return (
    <PrintContainer>
      <h1>Sipariş Listesi</h1>
      <ul>
        <li>
          <div>Kod</div>
          <div>Ad</div>
          <div> Tutar</div>
          <div>Tarih</div>
          <div>Durum</div>
        </li>
        {(orders!=null ? orders :[]).map((x) => {
          return (
            <li>
              <div>{x.orderCode}</div>
              <div>{x.detail}</div>
              <div>
                {x.currencyUnit}
                {x.totalCurrencyPrice}
              </div>
              <div>{x.date}</div>
              <div>{x.status}</div>
            </li>
          );
        })}
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Toplam : {total}</div>
        </li>
      </ul>
    </PrintContainer>
  );
}
