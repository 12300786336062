
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import {FooterContainer} from '../../styles/Header'
import logo from "../../mainlogo4_footer.png";
import mastercard from "../../master.png";
import visacard from "../../visa.jpeg";

import { useHistory } from 'react-router-dom';
export default function Footer() {
  const history = useHistory();
  return (
    <FooterContainer>
      <div className="black-footer">
        <div className="logo foot-item">
          <img src={logo} />
        </div>

        <div className="foot-item">
          <div
            className="c-item"
            onClick={() =>
              window.open("mailto:mehmetsamiaknur@gmail.com", "_blank")
            }
          >
            <h1>Destek</h1>
            <span>mehmetsamiaknur@gmail.com</span>
          </div>

          <div className="c-item">
            <h1>Gizlilik ve Güvenlik</h1>
            <span
              onClick={() => history.push("/gizlilik-politikasi")}
              style={{ cursor: "pointer" }}
            >
              Gizlilik Politikası
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/teslimat-ve-iade")}
            >
              Teslimat ve İade
            </span>
            <span
              onClick={() => history.push("/mesafeli-satis-sozlesmesi")}
              style={{ cursor: "pointer" }}
            >
              Mesafeli Satış Sözleşmesi
            </span>
          </div>
        </div>

        <div className="foot-item">
          <div className="c-item">
            <h1>Adres</h1>
            <span>
            Mengüçgazi Mah. Kültür Cad. No: 3/A Malazgirt/MUŞ
            </span>
          </div>

          <div className="c-item">
            <h1>Telefon</h1>
            <span onClick={() => window.open("tel:+904365112843", "_blank")}>
              0(436) 511 28 43
            </span>
            <span onClick={() => window.open("tel:+905454838787", "_blank")}>
              0(545) 484 8787
            </span>
     
          </div>
          <div className="c-item">
            <h1></h1>
            <span class="ads">
              <img
                src={mastercard}
                height={40}
                style={{
                  marginLeft: 10,
                  backgroundColor: "white",
                  padding: 5,
                  borderRadius: 5,
                }}
              />
              <img
                src={visacard}
                height={40}
                style={{
                  marginLeft: 10,
                  backgroundColor: "white",
                  padding: 5,
                  borderRadius: 5,
                }}
              />
            
            </span>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div
          
          style={{
            fontSize: 11,
            flexDirection: "column",
            justifyContent: "center",
            
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            margin: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 20,
              margin: 0,
            }}
          >
            <img
              src="https://dehasoft.com.tr/dehasoftblack.png"
              style={{
                height: 25,
                width: "unset",
                margin: 0,
                marginBottom: 15,
              }}
              onClick={() => window.open("https://dehasoft.com.tr/", "_blank")}
            />
          </div>{" "}
          <span>
            <a href="https://dehasoft.com.tr" target="_blank">
              dehasoft.com.tr
            </a>{" "}
            E-Ticaret/B2B Alt yapısı ile hazırlanmıştır.
          </span>
        </div>
      </div>
    </FooterContainer>
  );
}
