import React,{useState,useEffect} from 'react'
import {Login} from '../../styles/Auth'
import Box from '@mui/material/Box';

import { LoadingButton } from '@mui/lab';
import {  styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router';

import { useDispatch,useSelector } from 'react-redux'
import { loginChange,setCustomer } from '../stores/Auth';

import { useAuthRequests } from '../../helpers/AuthRequests';
import { Link } from 'react-router-dom';

export default function RegisterForm() {
  
  const {registerRequest} = useAuthRequests()

  const dispatch = useDispatch()

  const {login} = useSelector(state => state.auth)



  const [company,setCompany] = useState('')
  const [name, setName] = useState("");
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorPhoneNumber, setGuarantorPhoneNumber] = useState("");
  const [surname,setSurname] = useState('')
  const [address,setAddress] = useState('')
  const [phoneNumber,setPhoneNumber] = useState('')
  const [phoneNumber2,setPhoneNumber2] = useState('')
  const [email,setEmail] = useState('')
  
  const [password,setPassword] = useState('')

  const [checked,setChecked] = useState(false)

  const [loading,setLoading] = useState(false)

  const [error,setError] = useState('')
  const [success,setSuccess] = useState('')

  const history=useHistory()

  const RedditTextField = styled((props) => (<TextField InputProps={{ disableUnderline: true }} {...props} />))(({ theme }) => ({}));

  const sendRegister=async(e)=>{
    e.preventDefault();
    if(loading){
      return
    }else if(email=='' || email.length<5 || name.length<2 || surname.length<2 || company.length<5 ||  phoneNumber.length<5 || address.length<5   ){
      setError('Tüm alanları doldurunuz.')
    }else{
      setLoading(true)

      registerRequest(email,name,surname,company,phoneNumber,phoneNumber2,address,guarantorName,guarantorPhoneNumber)
      .then( async(response)=> {
        setEmail('')
        setPhoneNumber(0)
        setPhoneNumber2(0)
        setAddress('')
        setName('')
        setSurname('')
        setCompany('')
        setChecked(false)
        setError('')
        setSuccess(response.data.message+' Giriş syafasına  yönlendiriliyorsunuz.')
        
        setTimeout(() => {
          history.push('/giris-yap')
        }, 1000);

      })
      .catch( (error)=> {
        console.log(error)
        console.log(error.response)
        setError(error.response.data.message)
        setLoading(false)
      })

      

    }

    
  }
 
  return (
    <Login>
      <form onSubmit={sendRegister}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Ticari Ünvan"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Ad"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Soyad"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Adres"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Telefon 1"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="filled"
            type="number"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Referans Adı"
            value={guarantorName}
            onChange={(e) => setGuarantorName(e.target.value)}
            variant="filled"
            type="text"
            style={{ marginTop: 11 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="Referans Telefon"
            value={guarantorPhoneNumber}
            onChange={(e) => setGuarantorPhoneNumber(e.target.value)}
            variant="filled"
            type="number"
            style={{ marginTop: 11 }}
          />
        </Box>

        {/* <Box sx={{ display: 'flex',alignItems: 'center','& > :not(style)': { m: 1 },}}>
          <TextField  label="Telefon 2"  value={phoneNumber2} onChange={(e)=>setPhoneNumber2(e.target.value)}  variant="filled" type="number" style={{ marginTop: 11 }}/>
        </Box> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": { m: 1 },
          }}
        >
          <TextField
            label="E Posta"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="filled"
            type="email"
            style={{ marginTop: 11 }}
          />
        </Box>

        <label>
          {/* <Checkbox checked={checked} onChange={()=>setChecked(!checked)} {...label}  /> Beni hatırla */}
        </label>
        {error != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="error-area">{error}</div>{" "}
          </Box>
        )}
        {success != "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 1 },
            }}
          >
            {" "}
            <div className="success-area">{success}</div>{" "}
          </Box>
        )}
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          className="authBtn"
        >
          Kayıt Başvurusu Gönder
        </LoadingButton>

        <div className="form-navigator">
          <Link style={{ color: "#000" }} to="/giris-yap">
            Giriş Yap
          </Link>
          {/* <Link to="/sifremi-unuttum" >Şifremi Unuttum</Link>   */}
        </div>
      </form>
    </Login>
  );
}
