import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React,{useState,useLayoutEffect} from 'react'
import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory } from 'react-router-dom';
export default function PopUp({open,setOpen}) {
  const {getCategories} = useProductRequests();

  const [categories, setCategories] = useState([])

  const history=useHistory()
  

  useLayoutEffect(() => {
    
    if(categories.length === 0){
      getCategories().then((response)=>{
      }).catch((error)=>{
        console.log("error",error)
      })
    }
    
  }, [])
  return (
    <div className='pop-up' style={{top:!open ? '-400vh' : '0'}}>

      <div className='close'>
        <Button  variant='contained' color="error" onClick={()=>setOpen(false)}>
          <FontAwesomeIcon  icon={faTimes} />
        </Button>
      </div>
      <ul className='category-list'>
        {
          categories.map((category,index)=>{
            return <li  key={index}>
              <button onClick={()=> {
                history.push('/kategori/'+category.slug);
                setOpen(false);
              }}>{category.name}</button>
              {/* Alt  kategoriler burada ;  */}
              {
                category.sub_categories.length > 0 ?
                <ul className='sub-categories'>
                  {
                    category.sub_categories.map((sub_category,index)=>{
                      return <li  key={sub_category.slug}>
                        <button onClick={()=>{
                          history.push('/kategori/'+sub_category.slug);
                          setOpen(false);
                        }}>{sub_category.name}</button>
                        
                      </li>
                    })
                  }
                </ul>
                :
                // <div>
                //   <CategoryLoader head={'li'} count={10} />
                // </div>
                <></>
              }
              </li>
          })
        }
      </ul>

    </div>
  )
}
