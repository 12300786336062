import React from 'react'
import ContentLoader from 'react-content-loader'

export default class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: props.count,
      head: props.head
    }
  }

  render() {
    return (

      <>
      {(() => {
          const options = [];

          for (let i = 0; i <= this.state.count; i++) {
            options.push(
              <React.Fragment key={"dfagfdhasha"+i+"afgaadga"+(Math.floor(Math.random()*10))}>
              {
                this.state.head=='li' ? 
                <li key={"adfgdhadhdafhad"+i}> 
                  <ContentLoader speed={0.5} width={70} height={8}  backgroundColor="#ecebeb" foregroundColor="#f3f3f3" >
                    <rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                    <rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                  </ContentLoader>
                </li>
                :
                <ContentLoader key={"adfgdhadhdafhad"+i} speed={0.5} width={70} height={8}  backgroundColor="#ecebeb" foregroundColor="#f3f3f3" >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                </ContentLoader>
              }
              
              </React.Fragment>
            );
          }

          return options;
        })()}
      </>

    )
    
    
  }
}
